:root {
  --colorPrimaryL: #c81631;
  --colorPrimary: #981125;
  --colorPrimaryD: #4e0813;
  --colorSecondL: #FFC692;
  --colorSecond: #7E4C09;
  --colorSecondD: #3f2605;
  --colorGray50: #f5f5f5;
  --colorGray100: #dfe0e0;
  --colorGray200: #d0d1d1;
  --colorGray300: #bbbbbc;
  --colorGray400: #adaeaf;
  --colorGray500: #999a9b;
  --colorGray600: #8b8c8d;
  --colorGray700: #6d6d6e;
  --colorGray800: #545555;
  --colorGray900: #404141;
  --colorBlack: #0B0C0C;
  --colorWhite: #ffffff;
  --gradient-primaryL: linear-gradient(180deg, #ED3B52 -8.85%, #AC1B20 100%);
  --gradient-primary: linear-gradient(0deg, #88100D 14.83%, #980823 49.93%);
  --gradient-secoundL: linear-gradient(0deg, #FFC692 -73.08%, #FFEFB6 46.23%, #FFEC8B 103.68%);
  --gradient-secound: linear-gradient(0deg, #D09131 0%, #F8D76D 40.97%, #FFF 79.41%, #F8D76D 105.43%, #FFF 140%);
  --gradient-secoundD: linear-gradient(0deg, #F8D76D 1.23%, #D09131 73.15%, #F8D76D 87.53%, #FFF 109.45%, #FFF 111.64%);
  --gradient-black: linear-gradient(122deg, #181010 25%, #51515d 100%);
  --gradient-grey: linear-gradient(40deg, #adabab 25%, #777 100%);
  --gradient-silver: radial-gradient(91.77% 115.83% at 22.77% 18.75%,#fff 0%,#d9d9d9 100%);
  --baseFontsize: 16px;
}

p {
  font-size: 0.875rem;
  line-height: normal;
}

.jBox-wrapper {
  z-index: 99999 !important;
}

.jBox-overlay {
  z-index: 99998 !important;
  background-color: rgba(0, 0, 0, 0.5);
}

.jBox-Modal .jBox-container {
  overflow: hidden;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  background-color: transparent;
}

.jBox-Modal .jBox-container,
.jBox-Modal.jBox-closeButton-box:before {
  box-shadow: none;
}

.jBox-Modal .jBox-content {
  padding: 0 !important;
  margin: 0 auto !important;
}

.jBox-closeButton-box .jBox-closeButton {
  width: 30px;
  height: 30px;
  background: url(../img/ui/form/ico-close.svg) center center no-repeat;
  background-size: 70% auto;
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  right: 0px;
  top: 0px;
  overflow: hidden;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
}
.jBox-closeButton-box .jBox-closeButton:hover {
  background-color: var(--colorSecond);
}
.jBox-closeButton-box .jBox-closeButton svg {
  display: none;
}

.bModal {
  display: none;
  overflow: hidden;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  padding: 0px;
  background-color: transparent;
  margin: 0px;
  -webkit-box-shadow: 0px 0px 10px rgba(16, 50, 90, 0.2);
  -moz-box-shadow: 0px 0px 10px rgba(16, 50, 90, 0.2);
  box-shadow: 0px 0px 10px rgba(16, 50, 90, 0.2);
}
.bModal.bCard {
  padding: 0;
  background-color: #ffffff;
  padding: 24px;
  overflow: hidden;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.bModal.bCard .bInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 24px;
}
.bModal.bCard .bTitle {
  color: var(--colorPrimary);
  text-align: center;
}
.bModal.bCard .bTitle span {
  font-size: 1.25rem;
  line-height: normal;
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
}
.bModal.bCard .bDetails {
  text-align: center;
  color: var(--colorGray900);
  line-height: 160%;
}
.bModal.bCard .bText {
  margin-bottom: 24px;
  text-align: center;
}
.bModal.bCard .bText p {
  font-size: 1.25rem;
  line-height: normal;
}
@media (min-width: 768px) {
  .bModal.bCard .bText p {
    font-size: 1.5rem;
    line-height: normal;
  }
}

.bModal.VDO .bYoutube {
  width: 70vw;
  padding-top: 56.25%;
}
@media (min-width: 767px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .bModal.VDO .bYoutube {
    width: 80vw;
  }
}
@media (max-width: 767px) {
  .bModal.VDO .bYoutube {
    width: 90vw;
    padding: 0 20px;
    padding-top: 56.25%;
  }
}
.bModal.VDO iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.bModal.Image .bPic {
  width: 90vw;
}
@media (min-width: 768px) {
  .bModal.Image .bPic {
    width: 80vw;
  }
}
.bModal.Image a {
  color: #ffffff;
  margin-top: 16px;
}
.bModal.Image img {
  height: auto;
  width: 100%;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .bModal.Image img {
    width: auto;
    max-height: 80vh;
  }
}

a.btnCloseModalIcon {
  overflow: hidden;
  width: 24px;
  height: 24px;
  background: url(../img/ui/form/ico-close.svg) center center no-repeat;
  position: absolute;
  top: 8px;
  right: 8px;
}

#ModalFilter.bCard {
  padding: 24px 16px;
}
#ModalFilter.bCard .bInner {
  row-gap: 0;
}
#ModalFilter .bFilterForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
#ModalFilter .bBtn {
  display: flex;
  column-gap: 16px;
}

#ModalConfirm .bCard .bInner {
  row-gap: 16px;
}
#ModalConfirm .bBtn {
  display: flex;
  column-gap: 16px;
}

#pFilterMain {
  margin-inline: auto;
  width: calc(100% - 48px);
  margin-top: -32px;
  margin-bottom: 16px;
}
#pFilterMain .bFilter {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  background-color: #ffffff;
  height: 40px;
}
#pFilterMain .bFilter .btnFilter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  color: var(--colorGray900);
}
#pFilterMain .bFilter .btnFilter:after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background: url(../img/ui/form/ico-calendar.svg) center center no-repeat;
  background-size: auto 100%;
}

#pListWarrantyMain {
  margin-inline: auto;
  width: calc(100% - 48px);
}
#pListWarrantyMain .bListWarranty .bGroup {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
#pListWarrantyMain .bListWarranty .bGroup article {
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
#pListWarrantyMain .bListWarranty .bGroup article .bHead {
  background: var(--gradient-secound);
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  line-height: normal;
  color: var(--colorSecond);
}
#pListWarrantyMain .bListWarranty .bGroup article .bHead h2 {
  font-weight: 500;
  font-size: 1rem;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  column-gap: 4px;
}
#pListWarrantyMain .bListWarranty .bGroup article .bHead h2:before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background: url(../img/ci/ico-check.svg) center center no-repeat;
  background-size: auto 100%;
}
#pListWarrantyMain .bListWarranty .bGroup article .bDetails {
  padding: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
#pListWarrantyMain .bListWarranty .bGroup article .bDetails .bRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--colorGray700);
}
#pListWarrantyMain .bListWarranty .bGroup article .bDetails .bRow:first-child {
  color: var(--colorPrimary);
}
#pListWarrantyMain .bListWarranty .bGroup article .bDetails .bRow:last-child {
  padding-bottom: 8px;
  border-bottom: 1px solid #E9E9E9;
}
#pListWarrantyMain .bListWarranty .bGroup article .bDetails .bRow:last-child p:last-child {
  color: var(--colorGray900);
}
#pListWarrantyMain .bListWarranty .bGroup article .bBtn {
  display: flex;
  justify-content: center;
  padding: 0px 8px 8px;
}