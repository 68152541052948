/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 1, 2023 */



@font-face {
    font-family: 'sri_sury_wong';
    src: url('sri_sury_wongse_bold-webfont.eot');
    src: url('sri_sury_wongse_bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('sri_sury_wongse_bold-webfont.woff2') format('woff2'),
         url('sri_sury_wongse_bold-webfont.woff') format('woff'),
         url('sri_sury_wongse_bold-webfont.ttf') format('truetype'),
         url('sri_sury_wongse_bold-webfont.svg#sri_sury_wongseregular') format('svg');
    font-weight: normal;
    font-style: normal;

}