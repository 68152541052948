:root {
  --colorPrimaryL: #c81631;
  --colorPrimary: #981125;
  --colorPrimaryD: #4e0813;
  --colorSecondL: #FFC692;
  --colorSecond: #7E4C09;
  --colorSecondD: #3f2605;
  --colorGray50: #f5f5f5;
  --colorGray100: #dfe0e0;
  --colorGray200: #d0d1d1;
  --colorGray300: #bbbbbc;
  --colorGray400: #adaeaf;
  --colorGray500: #999a9b;
  --colorGray600: #8b8c8d;
  --colorGray700: #6d6d6e;
  --colorGray800: #545555;
  --colorGray900: #404141;
  --colorBlack: #0B0C0C;
  --colorWhite: #ffffff;
  --gradient-primaryL: linear-gradient(180deg, #ED3B52 -8.85%, #AC1B20 100%);
  --gradient-primary: linear-gradient(0deg, #88100D 14.83%, #980823 49.93%);
  --gradient-secoundL: linear-gradient(0deg, #FFC692 -73.08%, #FFEFB6 46.23%, #FFEC8B 103.68%);
  --gradient-secound: linear-gradient(0deg, #D09131 0%, #F8D76D 40.97%, #FFF 79.41%, #F8D76D 105.43%, #FFF 140%);
  --gradient-secoundD: linear-gradient(0deg, #F8D76D 1.23%, #D09131 73.15%, #F8D76D 87.53%, #FFF 109.45%, #FFF 111.64%);
  --gradient-black: linear-gradient(122deg, #181010 25%, #51515d 100%);
  --gradient-grey: linear-gradient(40deg, #adabab 25%, #777 100%);
  --gradient-silver: radial-gradient(91.77% 115.83% at 22.77% 18.75%,#fff 0%,#d9d9d9 100%);
  --baseFontsize: 16px;
}

p {
  font-size: 0.875rem;
  line-height: normal;
}

#pMembercardMain {
  margin-bottom: 32px;
}
#pMembercardMain .bMembercard {
  margin-inline: auto;
  width: calc(100% - 48px);
  display: flex;
  justify-content: center;
}
#pMembercardMain .bMembercard .bCardMain {
  background: var(--gradient-secoundD);
  overflow: hidden;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  position: relative;
  overflow: inherit;
  margin-top: -32px;
  box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
  z-index: 2;
  width: 100%;
  max-width: 327px;
  padding-top: 185px;
}
#pMembercardMain .bMembercard .bCardMain > * {
  position: relative;
}
#pMembercardMain .bMembercard .bCardMain .bCard {
  margin: 3px;
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  overflow: hidden;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
#pMembercardMain .bMembercard .bCardMain .bCard .bFront,
#pMembercardMain .bMembercard .bCardMain .bCard .bBack {
  background: center center no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
}
#pMembercardMain .bMembercard .bCardMain .bCard .bFront .bInfo,
#pMembercardMain .bMembercard .bCardMain .bCard .bBack .bInfo {
  display: flex;
  padding: 16px;
  align-items: center;
  column-gap: 16px;
  height: 100%;
  padding-bottom: 30px;
}
#pMembercardMain .bMembercard .bCardMain .bCard .bFront {
  background-image: url(../img/ci/card/bg-card-front.svg);
  position: absolute;
  width: 100%;
  height: 100%;
}
#pMembercardMain .bMembercard .bCardMain .bCard .bFront .bInfo .bId {
  position: absolute;
  top: 0;
  left: 16px;
  color: var(--colorSecond);
  font-size: 0.875rem;
  line-height: normal;
}
#pMembercardMain .bMembercard .bCardMain .bCard .bFront .bInfo .bText {
  color: #ffffff;
  margin-top: 36px;
}
#pMembercardMain .bMembercard .bCardMain .bCard .bFront .bInfo .bText .tPoint {
  font-size: 0.875rem;
  line-height: normal;
  line-height: 0;
  margin-bottom: 4px;
}
#pMembercardMain .bMembercard .bCardMain .bCard .bFront .bInfo .bText .tPoint span {
  display: block;
  font-family: "sri_sury_wong", sans-serif;
  font-size: 2.5rem;
  background: var(--gradient-secound);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#pMembercardMain .bMembercard .bCardMain .bCard .bFront .bInfo .bText .tPoint span:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(../img/ci/card/ico-point.svg) center center no-repeat;
  margin-right: 8px;
}
#pMembercardMain .bMembercard .bCardMain .bCard .bFront .bInfo .bText .tName {
  font-weight: bold;
  font-size: 1rem;
  line-height: normal;
  margin-bottom: 8px;
}
#pMembercardMain .bMembercard .bCardMain .bCard .bFront .bInfo .bText .tType {
  font-size: 0.875rem;
  line-height: normal;
}
#pMembercardMain .bMembercard .bCardMain .bCard .bBack {
  background-image: url(../img/ci/card/bg-card-back.svg);
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
}
#pMembercardMain .bMembercard .bCardMain .bCard .bBack .bInfo .bQR {
  background-color: #ffffff;
  margin-bottom: 0;
}
#pMembercardMain .bMembercard .bCardMain .bCard .bBack .bInfo .bQR img, #pMembercardMain .bMembercard .bCardMain .bCard .bBack .bInfo .bQR svg {
  display: block;
  width: 115px;
  height: auto;
}
#pMembercardMain .bMembercard .bCardMain .bCard .bBack .bInfo .bText {
  color: #ffffff;
  text-align: left;
}
#pMembercardMain .bMembercard .bCardMain .bCard .bBack .bInfo .bText span {
  display: block;
  font-size: 0.875rem;
  line-height: normal;
  margin-bottom: 4px;
}
#pMembercardMain .bMembercard .bCardMain .bCard .bBack .bInfo .bText p {
  font-size: 0.75rem;
  line-height: normal;
  line-height: 160%;
}
#pMembercardMain .bMembercard .bCardMain .bBtn {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  bottom: -16px;
}
#pMembercardMain .bMembercard .bCardMain .bBtn #btnViewQR {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--gradient-secound);
  width: 180px;
  height: 36px;
  line-height: 36px;
  overflow: hidden;
  border-radius: 36px;
  -webkit-border-radius: 36px;
  -moz-border-radius: 36px;
  -ms-border-radius: 36px;
  -o-border-radius: 36px;
  cursor: pointer;
}
#pMembercardMain .bMembercard .bCardMain .bBtn #btnViewQR div {
  color: var(--colorSecond);
  line-height: 36px;
  text-align: center;
  font-weight: 500;
}
#pMembercardMain .bMembercard .bCardMain .bBtn #btnViewQR div:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -2px;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  background: center center no-repeat;
  background-size: contain;
}
#pMembercardMain .bMembercard .bCardMain .bBtn #btnViewQR div.btnView:before {
  background-image: url(../img/ci/ico-barcode.svg);
}
#pMembercardMain .bMembercard .bCardMain .bBtn #btnViewQR div.btnClose {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  width: 100%;
}
#pMembercardMain .bMembercard .bCardMain .bBtn #btnViewQR div.btnClose:before {
  background-image: url(../img/ci/card/ico-close.svg);
}

#pProfileMain .bProfile {
  margin-inline: auto;
  width: calc(100% - 48px);
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 24px 16px;
}
#pProfileMain .bProfile .bGroup {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
#pProfileMain .bProfile .bRow {
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
}
#pProfileMain .bProfile .bRow p {
  font-size: 0.875rem;
  line-height: normal;
  color: var(--colorGray900);
}
#pProfileMain .bProfile .bRow p:first-child {
  color: var(--colorGray500);
}
#pProfileMain .bProfile .bBtn {
  display: flex;
  justify-content: flex-end;
}
#pProfileMain .bProfile .bBtn a {
  font-size: 0.875rem;
  line-height: normal;
  padding: 4px 8px;
  overflow: hidden;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border: 1px solid var(--colorGray700);
  color: var(--colorGray700);
}