@charset "UTF-8";
:root {
  --colorPrimaryL: #c81631;
  --colorPrimary: #981125;
  --colorPrimaryD: #4e0813;
  --colorSecondL: #FFC692;
  --colorSecond: #7E4C09;
  --colorSecondD: #3f2605;
  --colorGray50: #f5f5f5;
  --colorGray100: #dfe0e0;
  --colorGray200: #d0d1d1;
  --colorGray300: #bbbbbc;
  --colorGray400: #adaeaf;
  --colorGray500: #999a9b;
  --colorGray600: #8b8c8d;
  --colorGray700: #6d6d6e;
  --colorGray800: #545555;
  --colorGray900: #404141;
  --colorBlack: #0B0C0C;
  --colorWhite: #ffffff;
  --gradient-primaryL: linear-gradient(180deg, #ED3B52 -8.85%, #AC1B20 100%);
  --gradient-primary: linear-gradient(0deg, #88100D 14.83%, #980823 49.93%);
  --gradient-secoundL: linear-gradient(0deg, #FFC692 -73.08%, #FFEFB6 46.23%, #FFEC8B 103.68%);
  --gradient-secound: linear-gradient(0deg, #D09131 0%, #F8D76D 40.97%, #FFF 79.41%, #F8D76D 105.43%, #FFF 140%);
  --gradient-secoundD: linear-gradient(0deg, #F8D76D 1.23%, #D09131 73.15%, #F8D76D 87.53%, #FFF 109.45%, #FFF 111.64%);
  --gradient-black: linear-gradient(122deg, #181010 25%, #51515d 100%);
  --gradient-grey: linear-gradient(40deg, #adabab 25%, #777 100%);
  --gradient-silver: radial-gradient(91.77% 115.83% at 22.77% 18.75%,#fff 0%,#d9d9d9 100%);
  --baseFontsize: 16px;
}

p {
  font-size: 0.875rem;
  line-height: normal;
}

#pagePolicy .wrapContent {
  background: var(--colorGray100);
}
#pagePolicy #pPolicyMain {
  margin-inline: auto;
  width: calc(100% - 48px);
  padding-top: 30px;
}
#pagePolicy #pPolicyMain .bPolicy {
  background-color: #ffffff;
  padding: 24px;
  overflow: hidden;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
#pagePolicy #pPolicyMain .bPolicy > h1 {
  font-family: "sri_sury_wong", sans-serif;
  color: var(--colorPrimary);
  font-size: 2.5rem;
  line-height: 100%;
  margin-bottom: 16px;
}
#pagePolicy #pPolicyMain .bPolicy .bText * {
  font-size: 12px;
  line-height: 140%;
  margin-bottom: 16px;
  color: var(--colorGray900);
}
#pagePolicy #pPolicyMain .bPolicy .bText *:last-child,
#pagePolicy #pPolicyMain .bPolicy .bText * * > * {
  margin-bottom: 0;
}
#pagePolicy #pPolicyMain .bPolicy .bText h1,
#pagePolicy #pPolicyMain .bPolicy .bText h2,
#pagePolicy #pPolicyMain .bPolicy .bText h3,
#pagePolicy #pPolicyMain .bPolicy .bText h4,
#pagePolicy #pPolicyMain .bPolicy .bText h5,
#pagePolicy #pPolicyMain .bPolicy .bText h6 {
  margin-bottom: 16px;
  color: var(--colorBlack);
}
#pagePolicy #pPolicyMain .bPolicy .bText h1 {
  font-size: 24px;
}
#pagePolicy #pPolicyMain .bPolicy .bText h2 {
  font-size: 21.6px;
}
#pagePolicy #pPolicyMain .bPolicy .bText h3 {
  font-size: 19.2px;
}
#pagePolicy #pPolicyMain .bPolicy .bText h4 {
  font-size: 16.8px;
}
#pagePolicy #pPolicyMain .bPolicy .bText h5 {
  font-size: 14.4px;
}
#pagePolicy #pPolicyMain .bPolicy .bText h6 {
  font-size: 12px;
}
#pagePolicy #pPolicyMain .bPolicy .bText img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
#pagePolicy #pPolicyMain .bPolicy .bText span {
  color: var(--colorPrimary);
}
#pagePolicy #pPolicyMain .bPolicy .bText a {
  word-break: break-all;
  color: var(--colorPrimary);
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
}
#pagePolicy #pPolicyMain .bPolicy .bText a:hover {
  text-decoration: underline;
  color: var(--colorSecond);
}
#pagePolicy #pPolicyMain .bPolicy .bText blockquote {
  color: var(--colorBlack);
  background: #f9f9f9;
  border-left: 10px solid var(--colorSecondL);
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "“" "”" "‘" "’";
}
#pagePolicy #pPolicyMain .bPolicy .bText blockquote:before {
  color: var(--colorPrimary);
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
#pagePolicy #pPolicyMain .bPolicy .bText blockquote p {
  display: inline;
}
#pagePolicy #pPolicyMain .bPolicy .bText hr {
  width: 100%;
  height: 1px;
  background-color: var(--colorGray100);
}
#pagePolicy #pPolicyMain .bPolicy .bText table {
  width: 100%;
}
#pagePolicy #pPolicyMain .bPolicy .bText table td {
  padding: 6px 6px;
}
#pagePolicy #pPolicyMain .bPolicy .bText dl {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
}
#pagePolicy #pPolicyMain .bPolicy .bText dl dt,
#pagePolicy #pPolicyMain .bPolicy .bText dl dd,
#pagePolicy #pPolicyMain .bPolicy .bText dl dt > *,
#pagePolicy #pPolicyMain .bPolicy .bText dl dd > * {
  margin-bottom: 18px;
}
#pagePolicy #pPolicyMain .bPolicy .bText dl dt:last-child,
#pagePolicy #pPolicyMain .bPolicy .bText dl dd:last-child,
#pagePolicy #pPolicyMain .bPolicy .bText dl dt > *:last-child,
#pagePolicy #pPolicyMain .bPolicy .bText dl dd > *:last-child {
  margin-bottom: 0;
}
#pagePolicy #pPolicyMain .bPolicy .bText ul li {
  list-style: disc;
  margin-bottom: 0;
}
#pagePolicy #pPolicyMain .bPolicy .bText b {
  font-weight: bold;
}
#pagePolicy #pPolicyMain .bPolicy .bText strong {
  font-weight: bolder;
}
#pagePolicy #pPolicyMain .bPolicy .bText h4 {
  color: var(--colorPrimary);
}