@charset "UTF-8";
:root {
  --colorPrimaryL: #c81631;
  --colorPrimary: #981125;
  --colorPrimaryD: #4e0813;
  --colorSecondL: #FFC692;
  --colorSecond: #7E4C09;
  --colorSecondD: #3f2605;
  --colorGray50: #f5f5f5;
  --colorGray100: #dfe0e0;
  --colorGray200: #d0d1d1;
  --colorGray300: #bbbbbc;
  --colorGray400: #adaeaf;
  --colorGray500: #999a9b;
  --colorGray600: #8b8c8d;
  --colorGray700: #6d6d6e;
  --colorGray800: #545555;
  --colorGray900: #404141;
  --colorBlack: #0B0C0C;
  --colorWhite: #ffffff;
  --gradient-primaryL: linear-gradient(180deg, #ED3B52 -8.85%, #AC1B20 100%);
  --gradient-primary: linear-gradient(0deg, #88100D 14.83%, #980823 49.93%);
  --gradient-secoundL: linear-gradient(0deg, #FFC692 -73.08%, #FFEFB6 46.23%, #FFEC8B 103.68%);
  --gradient-secound: linear-gradient(0deg, #D09131 0%, #F8D76D 40.97%, #FFF 79.41%, #F8D76D 105.43%, #FFF 140%);
  --gradient-secoundD: linear-gradient(0deg, #F8D76D 1.23%, #D09131 73.15%, #F8D76D 87.53%, #FFF 109.45%, #FFF 111.64%);
  --gradient-black: linear-gradient(122deg, #181010 25%, #51515d 100%);
  --gradient-grey: linear-gradient(40deg, #adabab 25%, #777 100%);
  --gradient-silver: radial-gradient(91.77% 115.83% at 22.77% 18.75%,#fff 0%,#d9d9d9 100%);
  --baseFontsize: 16px;
}

p {
  font-size: 0.875rem;
  line-height: normal;
}

#pageCoupon #pSubmenuMain,
#pageCoupon-History #pSubmenuMain {
  margin-inline: auto;
  width: calc(100% - 48px);
  margin-top: -16px;
  margin-bottom: 24px;
}
#pageCoupon #pSubmenuMain .bSubmenu,
#pageCoupon-History #pSubmenuMain .bSubmenu {
  width: 100%;
  height: 32px;
  overflow: hidden;
  border-radius: 32px;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  -ms-border-radius: 32px;
  -o-border-radius: 32px;
  box-shadow: 0px 0px 0px 1px var(--colorGray300) inset;
  background: var(--colorGray100);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 0;
  grid-row-gap: 0;
}
#pageCoupon #pSubmenuMain .bSubmenu a,
#pageCoupon-History #pSubmenuMain .bSubmenu a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--colorGray700);
  line-height: 28px;
}
#pageCoupon #pSubmenuMain .bSubmenu a.active,
#pageCoupon-History #pSubmenuMain .bSubmenu a.active {
  color: var(--colorSecond);
  overflow: hidden;
  border-radius: 32px;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  -ms-border-radius: 32px;
  -o-border-radius: 32px;
  height: 32px;
  background: var(--gradient-secound);
}
#pageCoupon #pSearchMain,
#pageCoupon-History #pSearchMain {
  margin-inline: auto;
  width: calc(100% - 48px);
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
}
#pageCoupon #pSearchMain .bSearch,
#pageCoupon-History #pSearchMain .bSearch {
  position: relative;
  width: 100%;
}
#pageCoupon #pSearchMain .bSearch:after,
#pageCoupon-History #pSearchMain .bSearch:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  left: 0;
  width: 12px;
  height: 12px;
  background: url(../img/ui/ico-search.svg) center center no-repeat;
}
#pageCoupon #pSearchMain .bSearch input[type=text],
#pageCoupon-History #pSearchMain .bSearch input[type=text] {
  position: relative;
  font-size: 0.875rem;
  line-height: normal;
  overflow: hidden;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  background: none;
  border: none;
  border-bottom: 1px solid #ffffff;
  box-shadow: none;
  padding: 8px;
  padding-left: 24px;
  height: 32px;
  line-height: 32px;
  color: #ffffff;
}
#pageCoupon #pSearchMain .bSearch input[type=text]::placeholder,
#pageCoupon-History #pSearchMain .bSearch input[type=text]::placeholder {
  color: #ffffff;
}
#pageCoupon #pSearchMain .bFilter,
#pageCoupon-History #pSearchMain .bFilter {
  position: relative;
  min-width: fit-content;
  display: flex;
  column-gap: 4px;
  border: 1px solid #ffffff;
  overflow: hidden;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  align-items: center;
  padding: 0 4px;
}
#pageCoupon #pSearchMain .bFilter:before,
#pageCoupon-History #pSearchMain .bFilter:before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background: url(../img/ci/coupon/ico-filter.svg) center center no-repeat;
  background-size: auto 100%;
}
#pageCoupon #pSearchMain .bFilter .select,
#pageCoupon-History #pSearchMain .bFilter .select {
  width: auto;
  background-color: transparent;
}
#pageCoupon #pSearchMain .bFilter .select select,
#pageCoupon-History #pSearchMain .bFilter .select select {
  font-size: 0.875rem;
  line-height: normal;
  height: 32px;
  background: none;
  border: none;
  color: #ffffff;
  padding: 0;
}
#pageCoupon #pSearchMain .bFilter .select:before,
#pageCoupon-History #pSearchMain .bFilter .select:before {
  display: none;
}
#pageCoupon #pSearchMain .bFilter .select select option,
#pageCoupon-History #pSearchMain .bFilter .select select option {
  background-color: #333;
}
#pageCoupon #pListCouponMain,
#pageCoupon-History #pListCouponMain {
  margin-inline: auto;
  width: calc(100% - 48px);
}
#pageCoupon #pListCouponMain .bListCoupon .bGroup,
#pageCoupon-History #pListCouponMain .bListCoupon .bGroup {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
#pageCoupon #pListCouponMain .bListCoupon .bGroup article,
#pageCoupon-History #pListCouponMain .bListCoupon .bGroup article {
  display: grid;
  grid-template-columns: calc(33% - 10px) 20px calc(67% - 10px);
  overflow: hidden;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
#pageCoupon #pListCouponMain .bListCoupon .bGroup article .bPic,
#pageCoupon-History #pListCouponMain .bListCoupon .bGroup article .bPic {
  background-color: #ffffff;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 4px;
}
#pageCoupon #pListCouponMain .bListCoupon .bGroup article .bPic img,
#pageCoupon-History #pListCouponMain .bListCoupon .bGroup article .bPic img {
  display: block;
  height: 62px;
  width: auto;
  margin: 8px 0;
}
#pageCoupon #pListCouponMain .bListCoupon .bGroup article .bPic a,
#pageCoupon-History #pListCouponMain .bListCoupon .bGroup article .bPic a {
  font-size: 0.5rem;
  color: var(--colorGray700);
}
#pageCoupon #pListCouponMain .bListCoupon .bGroup article .divider,
#pageCoupon-History #pListCouponMain .bListCoupon .bGroup article .divider {
  width: 100%;
  background: url(../img/ci/coupon/bg-coupon-white.svg) center center no-repeat;
  background-size: cover;
}
#pageCoupon #pListCouponMain .bListCoupon .bGroup article .bDetails,
#pageCoupon-History #pListCouponMain .bListCoupon .bGroup article .bDetails {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  padding: 12px;
  padding-left: 4px;
}
#pageCoupon #pListCouponMain .bListCoupon .bGroup article .bDetails .bDate,
#pageCoupon-History #pListCouponMain .bListCoupon .bGroup article .bDetails .bDate {
  font-size: 0.625rem;
  color: var(--colorGray700);
}
#pageCoupon #pListCouponMain .bListCoupon .bGroup article .bDetails .bValue,
#pageCoupon-History #pListCouponMain .bListCoupon .bGroup article .bDetails .bValue {
  font-size: 0.625rem;
  color: var(--colorGray700);
}
#pageCoupon #pListCouponMain .bListCoupon .bGroup article .bDetails .bValue span,
#pageCoupon-History #pListCouponMain .bListCoupon .bGroup article .bDetails .bValue span {
  display: block;
  font-size: 0.75rem;
  line-height: normal;
  font-weight: 500;
  color: var(--colorPrimary);
  margin-bottom: 3px;
}
#pageCoupon #pListCouponMain .bListCoupon .bGroup article .bDetails .bPoint,
#pageCoupon-History #pListCouponMain .bListCoupon .bGroup article .bDetails .bPoint {
  display: flex;
  justify-content: space-between;
  column-gap: 8px;
  align-items: center;
}
#pageCoupon #pListCouponMain .bListCoupon .bGroup article .bDetails .bPoint > span,
#pageCoupon-History #pListCouponMain .bListCoupon .bGroup article .bDetails .bPoint > span {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 0.875rem;
  line-height: normal;
  color: var(--colorPrimary);
}
#pageCoupon #pListCouponMain .bListCoupon .bGroup article .bDetails .bPoint > span:before,
#pageCoupon-History #pListCouponMain .bListCoupon .bGroup article .bDetails .bPoint > span:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background: url(../img/ci/coupon/ico-point.svg) center center no-repeat;
  background-size: cover;
}
#pageCoupon #pListCouponMain .bListCoupon .bGroup article .bDetails .bPoint .bBtn a,
#pageCoupon-History #pListCouponMain .bListCoupon .bGroup article .bDetails .bPoint .bBtn a {
  font-size: 0.875rem;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  padding: 0 8px;
  height: 28px;
  background-color: var(--colorPrimary);
  color: #ffffff;
  flex-wrap: nowrap;
}
#pageCoupon #pListCouponMain .bListCoupon .bGroup.use article .bPic,
#pageCoupon #pListCouponMain .bListCoupon .bGroup.use article .bDetails,
#pageCoupon-History #pListCouponMain .bListCoupon .bGroup.use article .bPic,
#pageCoupon-History #pListCouponMain .bListCoupon .bGroup.use article .bDetails {
  background-color: var(--colorGray100);
}
#pageCoupon #pListCouponMain .bListCoupon .bGroup.use article .divider,
#pageCoupon-History #pListCouponMain .bListCoupon .bGroup.use article .divider {
  background-image: url(../img/ci/coupon/bg-coupon-gray.svg);
}
#pageCoupon #pListCouponMain .bListCoupon .bGroup.use article .bDetails,
#pageCoupon-History #pListCouponMain .bListCoupon .bGroup.use article .bDetails {
  background-color: var(--colorGray100);
}
#pageCoupon #pListCouponMain .bListCoupon .bGroup.use article .bDetails .bValue span,
#pageCoupon-History #pListCouponMain .bListCoupon .bGroup.use article .bDetails .bValue span {
  color: #570f12;
}
#pageCoupon #pListCouponMain .bListCoupon .bGroup.use article .bDetails .bPoint .bBtn a,
#pageCoupon-History #pListCouponMain .bListCoupon .bGroup.use article .bDetails .bPoint .bBtn a {
  background-color: #570f12;
}
#pageCoupon #pListCouponMain .bListCoupon .bGroup.use article .bDetails .bPoint span,
#pageCoupon-History #pListCouponMain .bListCoupon .bGroup.use article .bDetails .bPoint span {
  color: #570f12;
}
#pageCoupon #pListCouponMain .bListCoupon .bGroup.use article .bDetails .bPoint span:before,
#pageCoupon-History #pListCouponMain .bListCoupon .bGroup.use article .bDetails .bPoint span:before {
  background-image: url(../img/ci/coupon/ico-point-disable.svg);
}

#pageCoupon-Details #pCouponDetails {
  margin-inline: auto;
  width: calc(100% - 48px);
  padding-top: 30px;
}
#pageCoupon-Details #pCouponDetails .bCouponDetails {
  overflow: hidden;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background-color: #ffffff;
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bTitle {
  padding: 16px;
  border-bottom: 1px solid var(--colorGray100);
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bTitle h1 {
  font-size: 1rem;
  line-height: normal;
  font-weight: 500;
  color: var(--colorPrimary);
  text-align: center;
  margin-bottom: 8px;
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bTitle p {
  font-size: 0.75rem;
  line-height: normal;
  text-align: center;
  color: var(--colorGray700);
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bPic {
  padding: 28px;
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bPic img {
  display: block;
  width: auto;
  height: 166px;
  margin: 0 auto;
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bBtnTab {
  border-bottom: 1px solid var(--colorGray100);
  display: flex;
  justify-content: space-around;
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bBtnTab a {
  display: inline-block;
  padding: 12px 0;
  border-bottom: 4px solid transparent;
  font-size: 0.875rem;
  line-height: normal;
  color: var(--colorGray700);
  width: 72px;
  text-align: center;
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bBtnTab a.active {
  color: var(--colorPrimary);
  border-color: var(--colorPrimary);
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent {
  padding: 16px;
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent * {
  font-size: 12px;
  line-height: 140%;
  margin-bottom: 16px;
  color: var(--colorGray900);
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent *:last-child,
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent * * > * {
  margin-bottom: 0;
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent h1,
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent h2,
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent h3,
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent h4,
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent h5,
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent h6 {
  margin-bottom: 16px;
  color: var(--colorBlack);
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent h1 {
  font-size: 24px;
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent h2 {
  font-size: 21.6px;
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent h3 {
  font-size: 19.2px;
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent h4 {
  font-size: 16.8px;
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent h5 {
  font-size: 14.4px;
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent h6 {
  font-size: 12px;
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent span {
  color: var(--colorPrimary);
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent a {
  word-break: break-all;
  color: var(--colorPrimary);
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent a:hover {
  text-decoration: underline;
  color: var(--colorSecond);
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent blockquote {
  color: var(--colorBlack);
  background: #f9f9f9;
  border-left: 10px solid var(--colorSecondL);
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "“" "”" "‘" "’";
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent blockquote:before {
  color: var(--colorPrimary);
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent blockquote p {
  display: inline;
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent hr {
  width: 100%;
  height: 1px;
  background-color: var(--colorGray100);
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent table {
  width: 100%;
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent table td {
  padding: 6px 6px;
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent dl {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent dl dt,
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent dl dd,
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent dl dt > *,
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent dl dd > * {
  margin-bottom: 18px;
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent dl dt:last-child,
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent dl dd:last-child,
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent dl dt > *:last-child,
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent dl dd > *:last-child {
  margin-bottom: 0;
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent ul li {
  list-style: disc;
  margin-bottom: 0;
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent b {
  font-weight: bold;
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent strong {
  font-weight: bolder;
}
#pageCoupon-Details #pCouponDetails .bCouponDetails .bGroupTab .bTabContent#tab2 {
  display: none;
}

#pageCoupon-Scan #pCouponDetails {
  margin-inline: auto;
  width: calc(100% - 48px);
  padding-top: 30px;
}
#pageCoupon-Scan #pCouponDetails .bCouponDetails {
  overflow: hidden;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background-color: #ffffff;
}
#pageCoupon-Scan #pCouponDetails .bCouponDetails .bTitle {
  padding: 16px;
  border-bottom: 1px solid var(--colorGray100);
}
#pageCoupon-Scan #pCouponDetails .bCouponDetails .bTitle h1 {
  font-size: 1rem;
  line-height: normal;
  font-weight: 500;
  color: var(--colorPrimary);
  text-align: center;
  margin-bottom: 8px;
}
#pageCoupon-Scan #pCouponDetails .bCouponDetails .bTitle p {
  font-size: 0.75rem;
  line-height: normal;
  text-align: center;
  color: var(--colorGray700);
}
#pageCoupon-Scan #pCouponDetails .bCouponDetails .bPic {
  padding: 28px 0 16px;
  background-color: #ffffff;
}
#pageCoupon-Scan #pCouponDetails .bCouponDetails .bPic img, #pageCoupon-Scan #pCouponDetails .bCouponDetails .bPic svg {
  display: block;
  width: auto;
  margin: 60px auto;
}
#pageCoupon-Scan #pCouponDetails .bCouponDetails .bPic h2 {
  font-size: 1.25rem;
  line-height: normal;
  font-weight: bold;
  color: var(--colorPrimary);
  text-align: center;
}
#pageCoupon-Scan #pCouponDetails .bCouponDetails .bPic h3 {
  font-weight: normal;
  font-size: 0.875rem;
  line-height: normal;
  text-align: center;
  margin-bottom: 14px;
}
#pageCoupon-Scan #pCouponDetails .bCouponDetails .bPic p {
  font-size: 0.875rem;
  line-height: normal;
  text-align: center;
  color: #f85b56;
}
#pageCoupon-Scan #pCouponDetails .bCouponDetails .bTime {
  padding: 16px;
  border-top: 1px solid var(--colorGray100);
  border-bottom: 1px solid var(--colorGray100);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 0;
  grid-row-gap: 0;
}
#pageCoupon-Scan #pCouponDetails .bCouponDetails .bTime div {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  color: #000000;
}
#pageCoupon-Scan #pCouponDetails .bCouponDetails .bTime div span {
  font-size: 1.25rem;
  line-height: normal;
  font-weight: 500;
  color: #f85b56;
}
#pageCoupon-Scan #pCouponDetails .bCouponDetails .bTime div:first-child {
  font-size: 0.875rem;
  line-height: normal;
  color: #f85b56;
}
#pageCoupon-Scan #pCouponDetails .bCouponDetails .bTime div:nth-child(2) {
  border-left: 1px solid var(--colorGray100);
  border-right: 1px solid var(--colorGray100);
}
#pageCoupon-Scan #pCouponDetails .bCouponDetails .bTimeout {
  padding: 16px;
  border-top: 1px solid var(--colorGray100);
  border-bottom: 1px solid var(--colorGray100);
}
#pageCoupon-Scan #pCouponDetails .bCouponDetails .bTimeout p {
  text-align: center;
  font-weight: bold;
  color: #f85b56;
}

#pageCoupon-Use #pCouponDetails {
  margin-inline: auto;
  width: calc(100% - 48px);
  padding-top: 30px;
}
#pageCoupon-Use #pCouponDetails .bCouponDetails .bTitle {
  text-align: center;
  color: #ffffff;
}
#pageCoupon-Use #pCouponDetails .bCouponDetails .bTitle img {
  margin-bottom: 8px;
}
#pageCoupon-Use #pCouponDetails .bCouponDetails .bTitle h1 {
  font-size: 1.25rem;
  line-height: normal;
  font-weight: bold;
  text-align: center;
  margin-bottom: 16px;
}
#pageCoupon-Use #pCouponDetails .bCouponDetails .bTitle p {
  font-size: 0.75rem;
  line-height: normal;
  text-align: center;
}
#pageCoupon-Use #pCouponDetails .bCouponDetails .bTitle p span {
  font-size: 1rem;
  line-height: normal;
  font-weight: 500;
  display: block;
  margin-bottom: 4px;
}
#pageCoupon-Use #pCouponDetails .bCouponDetails .bPic img {
  display: block;
  width: auto;
  height: 166px;
  margin: 24px auto;
}
#pageCoupon-Use #pCouponDetails .bCouponDetails .bDetails {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
#pageCoupon-Use #pCouponDetails .bCouponDetails .bDetails h2 {
  font-size: 1rem;
  line-height: normal;
  font-weight: 500;
  padding: 12px 0;
  border-bottom: 1px solid #ffffff;
  margin-bottom: 4px;
}
#pageCoupon-Use #pCouponDetails .bCouponDetails .bDetails .bRow {
  display: flex;
  justify-content: space-between;
}