@import url("https://fonts.googleapis.com/css2?family=K2D:wght@400;500;700&display=swap");
@import url(../font/font.css);
:root {
  --colorPrimaryL: #c81631;
  --colorPrimary: #981125;
  --colorPrimaryD: #4e0813;
  --colorSecondL: #FFC692;
  --colorSecond: #7E4C09;
  --colorSecondD: #3f2605;
  --colorGray50: #f5f5f5;
  --colorGray100: #dfe0e0;
  --colorGray200: #d0d1d1;
  --colorGray300: #bbbbbc;
  --colorGray400: #adaeaf;
  --colorGray500: #999a9b;
  --colorGray600: #8b8c8d;
  --colorGray700: #6d6d6e;
  --colorGray800: #545555;
  --colorGray900: #404141;
  --colorBlack: #0B0C0C;
  --colorWhite: #ffffff;
  --gradient-primaryL: linear-gradient(180deg, #ED3B52 -8.85%, #AC1B20 100%);
  --gradient-primary: linear-gradient(0deg, #88100D 14.83%, #980823 49.93%);
  --gradient-secoundL: linear-gradient(0deg, #FFC692 -73.08%, #FFEFB6 46.23%, #FFEC8B 103.68%);
  --gradient-secound: linear-gradient(0deg, #D09131 0%, #F8D76D 40.97%, #FFF 79.41%, #F8D76D 105.43%, #FFF 140%);
  --gradient-secoundD: linear-gradient(0deg, #F8D76D 1.23%, #D09131 73.15%, #F8D76D 87.53%, #FFF 109.45%, #FFF 111.64%);
  --gradient-black: linear-gradient(122deg, #181010 25%, #51515d 100%);
  --gradient-grey: linear-gradient(40deg, #adabab 25%, #777 100%);
  --gradient-silver: radial-gradient(91.77% 115.83% at 22.77% 18.75%,#fff 0%,#d9d9d9 100%);
  --baseFontsize: 16px;
}

p {
  font-size: 0.875rem;
  line-height: normal;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 120%;
}

*:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  line-height: normal; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

a {
  background-color: transparent;
  text-decoration: none;
}

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

button,
input {
  /* 1 */
  overflow: visible;
}

button,
select {
  /* 1 */
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
}

ul, ol {
  padding-inline-start: 1.5em;
}

ul li::marker {
  font-size: 1em;
}

ol, ul, li {
  margin: 0;
}

hr {
  display: block;
  width: 100%;
  height: 1px;
  margin: 0;
  border: none;
  background-color: #000000;
}

section {
  position: relative;
}

.btn {
  overflow: hidden;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  font-size: 1rem;
  line-height: normal;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
  width: 100%;
  min-width: 120px;
  max-width: 221px;
  padding: 0 16px;
  color: var(--colorSecond);
  background-color: var(--colorPrimary);
  background: linear-gradient(0deg, #D09131 0%, #F8D76D 40.97%, #FFF 79.41%, #F8D76D 105.43%, #FFF 140%);
  border: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
}
.btn.outline {
  background: #ffffff;
  color: var(--colorGray700);
  border: 1px solid var(--colorGray700);
  box-shadow: none;
}
.btn.small {
  font-size: 1rem;
  line-height: normal;
  font-weight: 500;
  min-width: 150px;
  height: 44px;
  line-height: 44px;
}
.btn.disabled, .btn:disabled {
  background: var(--colorGray100);
  color: var(--colorGray700);
  border-color: var(--colorGray100);
  cursor: unset;
}
.btn.facebook {
  background: #2081e2;
  border-color: #2081e2;
  color: #ffffff;
  font-weight: bold;
}
.btn.facebook i {
  display: block;
  width: 24px;
  height: 24px;
  background: url(../img/ui/button/ico-fb.svg) center center no-repeat;
  background-size: auto 100%;
  margin-right: 10px;
}
.btn.facebook:hover {
  background-color: #2e89ff;
  border-color: #2e89ff;
}
.btn.download i {
  display: block;
  width: 24px;
  height: 24px;
  background: url(../img/ui/button/ico-download.svg) center center no-repeat;
  background-size: auto 100%;
  margin-right: 10px;
}
.btn.red {
  color: #ffffff;
  background: var(--colorPrimary);
}

.btnTop {
  color: #424242;
  display: flex;
  align-items: center;
}
.btnTop:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background: url(../img/ui/button/ico-top.svg) center center no-repeat;
  background-size: 100% auto;
  margin-right: 8px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
}
.btnTop:hover {
  color: var(--colorPrimary);
}

.link {
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  column-gap: 12px;
}
.link:after {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  position: relative;
  content: "";
  display: block;
  right: 0;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  position: relative;
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border-top: 2px solid;
  border-left: 2px solid;
  border-color: var(--colorPrimary);
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
}
.link:hover:after {
  right: -10px;
}
.link.white {
  color: #ffffff;
}
.link.gray {
  color: #929292;
}
.link.down {
  font-size: 1rem;
}
@media (min-width: 1024px) {
  .link.down {
    font-size: 1.25rem;
  }
}
.link.down:after {
  right: inherit;
}
.link.down:hover {
  color: var(--colorPrimary);
}

a.btnBack {
  color: #424242;
  font-size: 0.875rem;
  line-height: normal;
  cursor: pointer;
  display: flex;
  align-items: center;
}
@media (min-width: 1024px) {
  a.btnBack {
    font-size: 1.5625rem;
  }
}
a.btnBack:before {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  position: relative;
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-top: 2px solid;
  border-left: 2px solid;
  border-color: #424242;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  margin-right: 10px;
  left: 0px;
}
a.btnBack:hover {
  color: var(--colorPrimary);
}
a.btnBack:hover:before {
  left: -10px;
}

#btnLoadmore:after {
  right: inherit;
  top: -2px;
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -o-transform: rotate(225deg);
}
#btnLoadmore:hover {
  color: var(--colorPrimary);
  border-color: transparent;
}
#btnLoadmore:hover:after {
  right: inherit;
  border-color: #ffffff;
}

#btnFilter {
  cursor: pointer;
  overflow: hidden;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
  background-color: #ffffff;
  width: 52px;
  height: 52px;
  border: 1px solid #D6D6D6;
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;
}
#btnFilter span {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}
#btnFilter i {
  display: block;
  width: 16px;
  height: 2px;
  background-color: #424242;
  position: relative;
}
#btnFilter i:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  content: "";
  display: block;
  overflow: hidden;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
  width: 7px;
  height: 7px;
  border: 2px solid #424242;
  background: #ffffff;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
}
#btnFilter i:first-child:before {
  left: -1px;
}
#btnFilter i:last-child:before {
  left: 11px;
}
#btnFilter:hover {
  background-color: #FFD6D6;
}
#btnFilter:hover i {
  background-color: var(--colorPrimary);
}
#btnFilter:hover i:before {
  border-color: var(--colorPrimary);
  background-color: #FFD6D6;
}
#btnFilter:hover i:first-child:before {
  left: 11px;
}
#btnFilter:hover i:last-child:before {
  left: -1px;
}
#btnFilter.active {
  background-color: var(--colorPrimary);
}
#btnFilter.active i {
  background-color: #ffffff;
}
#btnFilter.active i:before {
  border-color: #ffffff;
  background-color: var(--colorPrimary);
}
#btnFilter.active i:first-child:before {
  left: 11px;
}
#btnFilter.active i:last-child:before {
  left: -1px;
}

body {
  position: relative;
}
body.openedMenu {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.bHideArea {
  display: none;
  position: fixed;
  z-index: 94036;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

#btnMenu {
  width: 24px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
#btnMenu span {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  position: relative;
  width: 20px;
  height: 2px;
  background-color: transparent;
  overflow: hidden;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  overflow: inherit;
}
#btnMenu span:before, #btnMenu span:after {
  content: "";
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  display: block;
  height: 2px;
  background-color: var(--colorBlack);
  position: absolute;
  overflow: hidden;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}
#btnMenu span:before {
  width: 20px;
  top: 4px;
}
#btnMenu span:after {
  width: 20px;
  bottom: 4px;
}
#btnMenu.opened span:before, #btnMenu.opened span:after {
  background-color: var(--colorPrimary) !important;
}
#btnMenu.opened span:before {
  width: 100%;
  top: 0px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}
#btnMenu.opened span:after {
  width: 100%;
  bottom: 0px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}
@media (min-width: 1024px) {
  #btnMenu {
    display: none;
  }
}

header {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 94035;
  height: 54px;
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
}
header .bHeader {
  position: relative;
  margin-inline: auto;
  width: calc(100% - 48px);
  display: flex;
  justify-content: center;
  align-items: center;
}
header .bHeader .bLogo img {
  display: block;
  width: auto;
  height: 22px;
}
header .bHeader .bLang {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  right: 0;
}
header .bHeader .bLang .activeLang {
  display: flex;
  column-gap: 3px;
  padding: 8px;
  background-color: var(--colorGray50);
  border: 1px solid var(--colorGray300);
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}
header .bHeader .bLang .activeLang {
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 4px;
  color: var(--colorGray900);
}
header .bHeader .bLang .activeLang:before {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  overflow: hidden;
  background: center center no-repeat;
  background-size: cover;
}
header .bHeader .bLang .activeLang[data-lang=th]:before {
  background-image: url(../img/ui/menu/ico-th.svg);
}
header .bHeader .bLang .activeLang[data-lang=en]:before {
  background-image: url(../img/ui/menu/ico-en.svg);
}
header .bHeader .bLang .bListlang {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  position: absolute;
  right: 0;
  top: 100%;
  padding: 8px;
  background-color: var(--colorGray50);
  border: 1px solid var(--colorGray300);
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  flex-direction: column;
  row-gap: 12px;
  display: none;
}
header .bHeader .bLang .bListlang a {
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 4px;
  cursor: pointer;
  color: var(--colorGray900);
}
header .bHeader .bLang .bListlang a:before {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  overflow: hidden;
  background: center center no-repeat;
  background-size: cover;
}
header .bHeader .bLang .bListlang a[data-lang=th]:before {
  background-image: url(../img/ui/menu/ico-th.svg);
}
header .bHeader .bLang .bListlang a[data-lang=en]:before {
  background-image: url(../img/ui/menu/ico-en.svg);
}
header .bHeader .bLang:hover .bListlang {
  display: flex;
}

footer {
  background-color: #ffffff;
  border-top: 1px solid var(--colorGray500);
  padding: 48px 0;
}
@media (min-width: 768px) {
  footer {
    padding: 96px 0 48px;
  }
}
footer .bFooter {
  margin-inline: auto;
  width: calc(100% - 48px);
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  footer .bFooter {
    margin-bottom: 64px;
  }
}
footer .bCopyTerms {
  margin-inline: auto;
  width: calc(100% - 48px);
  display: flex;
  column-gap: 12px;
}
footer .bCopyTerms .bTerms a {
  border-left: 1px solid var(--colorGray800);
  padding: 0 12px;
  color: var(--colorGray800);
}
footer .bCopyTerms .bTerms a:hover {
  color: var(--colorPrimary);
}

section#pPdpa {
  z-index: 94035;
  box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  padding: 20px 0;
}
section#pPdpa p {
  font-size: 0.875rem;
}
section#pPdpa .bPdpa .bGroup {
  display: grid;
  grid-template-columns: 100%;
  grid-column-gap: 30px;
  justify-items: center;
}
@media (min-width: 768px) {
  section#pPdpa .bPdpa .bGroup {
    grid-column-gap: 10%;
    justify-items: start;
    grid-template-columns: auto auto;
  }
}
section#pPdpa .bPdpa .bGroup .bText {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  section#pPdpa .bPdpa .bGroup .bText {
    margin-bottom: 0;
  }
}
section#pPdpa .bPdpa .bGroup .bText span {
  font-size: 0.875rem;
  line-height: normal;
  font-weight: 500;
  color: #424242;
  text-transform: uppercase;
}
section#pPdpa .bPdpa .bGroup p {
  color: #424242;
  font-size: 1.25rem;
  line-height: normal;
}
section#pPdpa .bPdpa .bBtn {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}
@media (min-width: 768px) {
  section#pPdpa .bPdpa .bBtn {
    justify-content: flex-end;
  }
}
section#pPdpa .bPdpa .bPolicy {
  margin-bottom: 12px;
}
@media (min-width: 768px) {
  section#pPdpa .bPdpa .bPolicy {
    margin-bottom: 0;
  }
}
section#pPdpa .bPdpa .bPolicy a,
section#pPdpa .bPdpa .bPolicy span {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: normal;
  text-decoration: underline;
  color: #424242;
}
section#pPdpa .bPdpa .bPolicy span {
  margin: 0 4px;
}
section#pPdpa .bPdpa .bPolicy a:hover {
  color: var(--colorPrimary);
}

.inputStyle, .select > select, input[list],
input[type=text],
input[type=password],
input[type=textarea],
textarea,
input[type=date],
input[type=month],
input[type=year],
input[type=email] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: "K2D", sans-serif;
  font-weight: normal;
  line-height: normal;
  position: relative;
  width: 100%;
  outline: 0;
  border: 0;
  background-color: rgb(255, 255, 255);
  color: var(--colorGray700);
  height: 40px;
  line-height: 40px;
  padding: 8px 12px;
  overflow: hidden;
  border: 1px solid #e5e5e5;
  overflow: hidden;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  font-size: 1rem;
  line-height: normal;
  font-weight: 400;
}
.inputStyle::placeholder, .select > select::placeholder, input[list]::placeholder,
input[type=text]::placeholder,
input[type=password]::placeholder,
input[type=textarea]::placeholder,
textarea::placeholder,
input[type=date]::placeholder,
input[type=month]::placeholder,
input[type=year]::placeholder,
input[type=email]::placeholder, .inputStyle:-ms-input-placeholder, .select > select:-ms-input-placeholder, input[list]:-ms-input-placeholder,
input[type=text]:-ms-input-placeholder,
input[type=password]:-ms-input-placeholder,
input[type=textarea]:-ms-input-placeholder,
textarea:-ms-input-placeholder,
input[type=date]:-ms-input-placeholder,
input[type=month]:-ms-input-placeholder,
input[type=year]:-ms-input-placeholder,
input[type=email]:-ms-input-placeholder, .inputStyle::-ms-input-placeholder, .select > select::-ms-input-placeholder, input[list]::-ms-input-placeholder,
input[type=text]::-ms-input-placeholder,
input[type=password]::-ms-input-placeholder,
input[type=textarea]::-ms-input-placeholder,
textarea::-ms-input-placeholder,
input[type=date]::-ms-input-placeholder,
input[type=month]::-ms-input-placeholder,
input[type=year]::-ms-input-placeholder,
input[type=email]::-ms-input-placeholder {
  color: var(--colorGray200);
  opacity: 1;
}

input[list],
input[type=text],
input[type=password],
input[type=textarea],
textarea,
input[type=date],
input[type=month],
input[type=year],
input[type=email] {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  resize: none;
}
input[list]:disabled,
input[type=text]:disabled,
input[type=password]:disabled,
input[type=textarea]:disabled,
textarea:disabled,
input[type=date]:disabled,
input[type=month]:disabled,
input[type=year]:disabled,
input[type=email]:disabled {
  opacity: 0.6;
}
input[list]:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=textarea]:focus,
textarea:focus,
input[type=date]:focus,
input[type=month]:focus,
input[type=year]:focus,
input[type=email]:focus {
  border-color: #F3C941;
}

.control {
  display: block;
  position: relative;
  padding: 4px 14px;
  cursor: pointer;
  padding-left: 30px;
  font-size: 0.875rem;
  line-height: normal;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
@media (min-width: 1024px) {
  .control input {
    top: 70%;
  }
}

.control--checkbox .control__indicator {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  height: 20px;
  width: 20px;
  background: url(../img/ui/form/ico-check.svg) center center no-repeat no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  left: 0px;
}
.control--checkbox input:checked ~ .control__indicator {
  background-image: url(../img/ui/form/ico-checked.svg);
}
.control--checkbox input:disabled ~ .control__indicator {
  opacity: 0.6;
}
.control--checkbox input:disabled ~ .control__indicator:after {
  opacity: 0.6;
}

.control--radio {
  padding-left: 30px;
  color: var(--colorGray700);
}
.control--radio .control__indicator {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background-color: rgb(255, 255, 255);
  border: 1px solid var(--colorGray600);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  left: 0;
}
.control--radio input:checked ~ .control__indicator {
  border: 1px solid var(--colorPrimary);
}
.control--radio input:checked ~ .control__indicator:after {
  display: block;
}
.control--radio .control__indicator:after {
  content: "";
  position: absolute;
  display: none;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: var(--colorPrimary);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.control--radio input:disabled ~ .control__indicator {
  opacity: 0.6;
}
.control--radio input:disabled ~ .control__indicator:after {
  opacity: 0.6;
}

.select {
  position: relative;
  display: inline-block;
  width: 100%;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}
.select > select {
  display: inline-block;
  cursor: pointer;
  border: 1px solid #e5e5e5;
  background-color: transparent;
  padding-right: 36px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 170%;
}
.select > select::-ms-expand {
  display: none;
}
.select > select:disabled {
  opacity: 0.5;
  pointer-events: none;
}
.select:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  right: 0px;
  content: "";
  width: 40px;
  height: 100%;
  background: url(../img/ui/form/ico-arrow-select.svg) center center no-repeat;
}
@media (min-width: 1024px) {
  .select:before {
    width: 40px;
  }
}

.datalist {
  line-height: 33px;
}

datalist {
  display: none;
}

/* specifically hide the arrow on focus */
input::-webkit-calendar-picker-indicator {
  display: none;
  opacity: 0;
}

textarea {
  height: 120px;
  padding: 8px 16px;
  border-bottom: 1px solid #e5e5e5;
  line-height: normal;
  overflow: scroll;
}

input[type=date],
input[type=month],
input[type=year] {
  text-align: left;
  padding: 8px 40px 8px 12px;
}
input[type=date]:before,
input[type=month]:before,
input[type=year]:before {
  content: "";
  display: block;
  z-index: 0;
  right: 16px;
  width: 40px;
  height: 100%;
  background: url(../img/ui/form/ico-calendar.svg) center center no-repeat;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  right: 0;
}
input[type=date]::-webkit-calendar-picker-indicator,
input[type=month]::-webkit-calendar-picker-indicator,
input[type=year]::-webkit-calendar-picker-indicator {
  display: block;
  opacity: 1;
  width: 40px;
  height: 100%;
  background: transparent;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  z-index: 1;
  right: 0;
}
input[type=date]::-webkit-date-and-time-value,
input[type=month]::-webkit-date-and-time-value,
input[type=year]::-webkit-date-and-time-value {
  color: var(--colorGray700);
  text-align: left;
}

input[type=password] {
  padding-right: 36px;
}

.controlPass {
  position: relative;
}
.controlPass .btnshowPass {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  right: 7px;
  color: var(--colorGray300);
  width: 40px;
  height: 100%;
  overflow: hidden;
  text-indent: -999px;
  background: url(../img/ui/form/ico-invisible.svg) center center no-repeat;
}
@media (min-width: 1024px) {
  .controlPass .btnshowPass {
    width: 40px;
  }
}
.controlPass .btnshowPass:hover {
  color: var(--colorPrimary);
}
.controlPass .btnshowPass.show {
  background-image: url(../img/ui/form/ico-visibility.svg);
}

.checkboxPDPA {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.checkboxPDPA .control {
  line-height: 28px;
}
.checkboxPDPA .control input {
  top: 50%;
}
.checkboxPDPA .bText a:hover {
  color: var(--colorSecond);
}

.bCaptcha {
  margin-bottom: 25px;
}
.bCaptcha > img {
  width: 100%;
  max-width: 300px;
  height: auto;
}
@media (min-width: 1024px) {
  .bCaptcha > img {
    width: 360px;
  }
}

.control-group [data-content]:after {
  content: attr(data-content);
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  right: 20px;
  color: var(--colorSecond);
}
.control-group [data-content] input {
  padding-right: 20%;
}
.control-group .floating-label {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  display: block;
  position: absolute;
  opacity: 0;
  top: -10px;
  left: 0px;
  font-size: 0.75rem;
  line-height: normal;
  color: var(--colorGray500);
  white-space: nowrap;
}
.control-group .form-control:placeholder-shown + .floating-label {
  visibility: hidden;
  z-index: -1;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
}
.control-group .form-control:not(:placeholder-shown) + .floating-label,
.control-group .form-control:focus:not(:placeholder-shown) + .floating-label {
  visibility: visible;
  z-index: 1;
  opacity: 1;
  transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}

.bForm {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 16px;
}
.bForm > *:last-child {
  margin-bottom: 0;
}
.bForm .bRow {
  display: flex;
  column-gap: 16px;
}
.bForm .control-group {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.bForm .control-group [class*=tTitle] {
  font-size: 0.875rem;
  line-height: normal;
  font-weight: 500;
  color: var(--colorGray700);
}
.bForm .control-group [class*=tTitle] span {
  display: inline;
}
.bForm .control-group.validate [class*=tTitle]:after {
  content: "*";
  color: #a2191f;
}
.bForm .control-group.error input, .bForm .control-group.error select {
  border-color: #f85b56;
}
.bForm .control-group.error label.tError {
  display: flex;
}
.bForm .control-group.error label.tError:before {
  display: none;
}
.bForm .control-group.pdpa .control__indicator {
  top: 25%;
}
.bForm .control-group.pdpa p {
  color: var(--colorGray700);
  font-size: 0.75rem;
  line-height: normal;
  font-weight: 500;
}
.bForm .control-group.pdpa p a {
  text-decoration: underline;
}
.bForm .control-group.pdpa.error > .tError {
  display: flex;
}
.bForm .control-group.pdpa.error > .tError:before {
  display: block;
}
.bForm .control-group > p {
  font-size: 0.75rem;
  line-height: normal;
  color: var(--colorGray700);
}
.bForm .tError {
  display: none;
  font-size: 0.75rem;
  line-height: normal;
  color: #f85b56;
  font-weight: 300;
  display: none;
  align-items: center;
  column-gap: 8px;
}
.bForm .tError:before {
  content: "";
  display: block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background: url(../img/ui/form/ico-error.svg) center center no-repeat;
  background-size: contain;
}
.bForm.error > .tError {
  display: flex;
}

.jBox-wrapper {
  z-index: 99999 !important;
}

.jBox-overlay {
  z-index: 99998 !important;
  background-color: rgba(0, 0, 0, 0.5);
}

.jBox-Modal .jBox-container {
  overflow: hidden;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  background-color: transparent;
}

.jBox-Modal .jBox-container,
.jBox-Modal.jBox-closeButton-box:before {
  box-shadow: none;
}

.jBox-Modal .jBox-content {
  padding: 0 !important;
  margin: 0 auto !important;
}

.jBox-closeButton-box .jBox-closeButton {
  width: 30px;
  height: 30px;
  background: url(../img/ui/form/ico-close.svg) center center no-repeat;
  background-size: 70% auto;
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  right: 0px;
  top: 0px;
  overflow: hidden;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
}
.jBox-closeButton-box .jBox-closeButton:hover {
  background-color: var(--colorSecond);
}
.jBox-closeButton-box .jBox-closeButton svg {
  display: none;
}

.bModal {
  display: none;
  overflow: hidden;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  padding: 0px;
  background-color: transparent;
  margin: 0px;
  -webkit-box-shadow: 0px 0px 10px rgba(16, 50, 90, 0.2);
  -moz-box-shadow: 0px 0px 10px rgba(16, 50, 90, 0.2);
  box-shadow: 0px 0px 10px rgba(16, 50, 90, 0.2);
}
.bModal.bCard {
  padding: 0;
  background-color: #ffffff;
  padding: 24px;
  overflow: hidden;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.bModal.bCard .bInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 24px;
}
.bModal.bCard .bTitle {
  color: var(--colorPrimary);
  text-align: center;
}
.bModal.bCard .bTitle span {
  font-size: 1.25rem;
  line-height: normal;
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
}
.bModal.bCard .bDetails {
  text-align: center;
  color: var(--colorGray900);
  line-height: 160%;
}
.bModal.bCard .bText {
  margin-bottom: 24px;
  text-align: center;
}
.bModal.bCard .bText p {
  font-size: 1.25rem;
  line-height: normal;
}
@media (min-width: 768px) {
  .bModal.bCard .bText p {
    font-size: 1.5rem;
    line-height: normal;
  }
}

.bModal.VDO .bYoutube {
  width: 70vw;
  padding-top: 56.25%;
}
@media (min-width: 767px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .bModal.VDO .bYoutube {
    width: 80vw;
  }
}
@media (max-width: 767px) {
  .bModal.VDO .bYoutube {
    width: 90vw;
    padding: 0 20px;
    padding-top: 56.25%;
  }
}
.bModal.VDO iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.bModal.Image .bPic {
  width: 90vw;
}
@media (min-width: 768px) {
  .bModal.Image .bPic {
    width: 80vw;
  }
}
.bModal.Image a {
  color: #ffffff;
  margin-top: 16px;
}
.bModal.Image img {
  height: auto;
  width: 100%;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .bModal.Image img {
    width: auto;
    max-height: 80vh;
  }
}

a.btnCloseModalIcon {
  overflow: hidden;
  width: 24px;
  height: 24px;
  background: url(../img/ui/form/ico-close.svg) center center no-repeat;
  position: absolute;
  top: 8px;
  right: 8px;
}

#ModalFilter.bCard {
  padding: 24px 16px;
}
#ModalFilter.bCard .bInner {
  row-gap: 0;
}
#ModalFilter .bFilterForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
#ModalFilter .bBtn {
  display: flex;
  column-gap: 16px;
}

#ModalConfirm .bCard .bInner {
  row-gap: 16px;
}
#ModalConfirm .bBtn {
  display: flex;
  column-gap: 16px;
}

html {
  font-size: var(--baseFontsize);
}

body {
  font-family: "K2D", sans-serif;
  font-weight: normal;
  line-height: normal;
  font-size: 1rem;
  line-height: normal;
  color: var(--colorBlack);
  min-height: -webkit-fill-available;
  background: var(--gradient-secound);
  background-attachment: fixed;
  overflow-x: hidden;
}

.container {
  margin-inline: auto;
  width: calc(100% - 48px);
}

a {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  color: var(--colorPrimary);
}
a:hover {
  color: var(--colorSecond);
}

.bPreload {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  background-size: 100px auto;
  z-index: 94036;
}
.bPreload img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  display: block;
  width: auto;
  height: 50px;
}

div[id^=page] {
  width: 100%;
  max-width: 500px;
  margin-inline: auto;
  background-color: #f85b56;
}
div[id^=page] .wrapContent {
  position: relative;
  background: url(../img/ci/bg-main.svg) center center repeat, var(--gradient-primary);
  padding-bottom: 130px;
  min-height: calc(100vh - 54px);
}

.hide {
  display: none !important;
}

.pc {
  display: none;
}
@media (min-width: 767px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .pc {
    display: none;
  }
}
@media (min-width: 1024px) {
  .pc {
    display: block;
  }
}

.pc-tablet {
  display: none;
}
@media (min-width: 767px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .pc-tablet {
    display: block;
  }
}
@media (min-width: 768px) {
  .pc-tablet {
    display: block;
  }
}

.mobile-tablet {
  display: block;
}
@media (min-width: 767px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .mobile-tablet {
    display: block;
  }
}
@media (min-width: 768px) {
  .mobile-tablet {
    display: none;
  }
}

.mobile {
  display: block;
}
@media (min-width: 767px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .mobile {
    display: none;
  }
}
@media (min-width: 1024px) {
  .mobile {
    display: none;
  }
}

#pLoadmore .bLoadmore {
  border-top: 1px solid var(--colorGray300);
  text-align: center;
  padding-top: 32px;
}
@media (min-width: 768px) {
  #pLoadmore .bLoadmore {
    padding-top: 48px;
  }
}
#pLoadmore .bLoadmore p {
  color: var(--colorPrimary);
  font-weight: bold;
  font-size: 1.25rem;
  line-height: normal;
}
@media (min-width: 768px) {
  #pLoadmore .bLoadmore p {
    font-size: 1.5rem;
    line-height: normal;
  }
}

.bNoData {
  height: 100vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 16px;
}
.bNoData img {
  display: block;
  width: auto;
  height: 115px;
}
.bNoData p {
  font-size: 1rem;
  line-height: normal;
  color: #ffffff;
}

#pTiltlePage {
  border-bottom: 2px solid var(--colorSecondL);
  background: url(../img/ci/bg-title-top.jpg) center center no-repeat;
  background-size: cover;
}
#pTiltlePage .bTitle {
  margin-inline: auto;
  width: calc(100% - 48px);
  padding-top: 16px;
  padding-bottom: 54px;
}
#pTiltlePage .bTitle .tag {
  color: var(--colorPrimary);
  font-size: 0.75rem;
  line-height: normal;
  background-color: #ffffff;
  display: inline-flex;
  align-items: center;
  column-gap: 4px;
  padding: 0 8px;
  line-height: 24px;
  overflow: hidden;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  margin-bottom: 12px;
}
#pTiltlePage .bTitle .tag span {
  font-weight: bold;
}
#pTiltlePage .bTitle h1 {
  font-family: "sri_sury_wong", sans-serif;
  font-size: 2.5rem;
  color: #ffffff;
}
#pTiltlePage .bTitle h1:before {
  content: "";
  display: inline-block;
  height: 24px;
  width: 3px;
  margin-right: 8px;
  background: var(--gradient-secoundL);
}

#pTitleContentMain {
  margin-inline: auto;
  width: calc(100% - 48px);
  background: var(--gradient-secoundD);
  overflow: hidden;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  position: relative;
  overflow: inherit;
  margin-top: -32px;
  box-shadow: 0px 2px 10px 0px rgba(126, 76, 9, 0.3);
  z-index: 2;
}
#pTitleContentMain:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: linear-gradient(180deg, #d23549 -8.85%, #96090d 100%), linear-gradient(180deg, #cd0228 -8.85%, #88110d 100%);
  overflow: hidden;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
#pTitleContentMain > * {
  position: relative;
}
#pTitleContentMain .bTitleContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 8px;
  padding: 24px 32px;
}
#pTitleContentMain .bTitleContent .tTag {
  font-family: "sri_sury_wong", sans-serif;
  color: var(--colorPrimary);
  padding: 0 32px;
  background: url(../img/ci/bg-tag-part.svg) center center no-repeat;
  background-size: 100%;
  height: 24px;
  line-height: 24px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  top: -9px;
  white-space: nowrap;
}
#pTitleContentMain .bTitleContent h2 {
  font-family: "sri_sury_wong", sans-serif;
  font-size: 1.9rem;
  line-height: normal;
  background: linear-gradient(0deg, #D09131 -19.86%, #F8D76D 25.21%, #FFF 64.93%, #F8D76D 87.53%, #FFF 111.64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#pTitleContentMain .bTitleContent .tStep {
  position: relative;
  font-family: "sri_sury_wong", sans-serif;
  background: var(--gradient-secound);
  padding: 0 16px;
  height: 24px;
  overflow: hidden;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  bottom: -9px;
}
#pTitleContentMain .bTitleContent .tStep span {
  position: relative;
  background: var(--gradient-primaryL);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.5rem;
  line-height: normal;
  line-height: 23px;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

#pActionBtn {
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 500px;
  z-index: 999;
}
#pActionBtn .bBtn {
  margin-inline: auto;
  width: calc(100% - 48px);
  display: flex;
  justify-content: center;
  column-gap: 20px;
  padding: 24px 0;
}

#pActionMenu {
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 500px;
  z-index: 999;
}
#pActionMenu .bBtn {
  margin-inline: auto;
  width: calc(100% - 48px);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 0;
  grid-row-gap: 0;
  align-items: flex-end;
  justify-content: center;
  padding: 12px 0;
}
#pActionMenu .bBtn a {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--colorGray900);
  row-gap: 8px;
  font-size: 0.875rem;
  line-height: normal;
}
#pActionMenu .bBtn a i {
  width: 36px;
  height: 36px;
  background-color: var(--colorGray200);
  overflow: hidden;
  border-radius: 36px;
  -webkit-border-radius: 36px;
  -moz-border-radius: 36px;
  -ms-border-radius: 36px;
  -o-border-radius: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#pActionMenu .bBtn a.active {
  color: var(--colorSecond);
}
#pActionMenu .bBtn a.active i {
  background: var(--gradient-secoundD);
}