:root {
  --colorPrimaryL: #c81631;
  --colorPrimary: #981125;
  --colorPrimaryD: #4e0813;
  --colorSecondL: #FFC692;
  --colorSecond: #7E4C09;
  --colorSecondD: #3f2605;
  --colorGray50: #f5f5f5;
  --colorGray100: #dfe0e0;
  --colorGray200: #d0d1d1;
  --colorGray300: #bbbbbc;
  --colorGray400: #adaeaf;
  --colorGray500: #999a9b;
  --colorGray600: #8b8c8d;
  --colorGray700: #6d6d6e;
  --colorGray800: #545555;
  --colorGray900: #404141;
  --colorBlack: #0B0C0C;
  --colorWhite: #ffffff;
  --gradient-primaryL: linear-gradient(180deg, #ED3B52 -8.85%, #AC1B20 100%);
  --gradient-primary: linear-gradient(0deg, #88100D 14.83%, #980823 49.93%);
  --gradient-secoundL: linear-gradient(0deg, #FFC692 -73.08%, #FFEFB6 46.23%, #FFEC8B 103.68%);
  --gradient-secound: linear-gradient(0deg, #D09131 0%, #F8D76D 40.97%, #FFF 79.41%, #F8D76D 105.43%, #FFF 140%);
  --gradient-secoundD: linear-gradient(0deg, #F8D76D 1.23%, #D09131 73.15%, #F8D76D 87.53%, #FFF 109.45%, #FFF 111.64%);
  --gradient-black: linear-gradient(122deg, #181010 25%, #51515d 100%);
  --gradient-grey: linear-gradient(40deg, #adabab 25%, #777 100%);
  --gradient-silver: radial-gradient(91.77% 115.83% at 22.77% 18.75%,#fff 0%,#d9d9d9 100%);
  --baseFontsize: 16px;
}

p {
  font-size: 0.875rem;
  line-height: normal;
}

#pWarrantyDetailsMain {
  margin-inline: auto;
  width: calc(100% - 48px);
  padding-top: 30px;
}
#pWarrantyDetailsMain .bWarrantyDetails {
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
#pWarrantyDetailsMain .bWarrantyDetails .bGroup {
  padding: 10px;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
#pWarrantyDetailsMain .bWarrantyDetails .bGroup .bRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#pWarrantyDetailsMain .bWarrantyDetails .bGroup .bRow p {
  color: var(--colorGray700);
}
#pWarrantyDetailsMain .bWarrantyDetails .bGroup .bRow p:last-child {
  color: var(--colorGray900);
}
#pWarrantyDetailsMain .bWarrantyDetails .bGroup.total .bRow:last-child p {
  color: var(--colorPrimary);
}
#pWarrantyDetailsMain .bWarrantyDetails .bGroup.point .bRow p {
  color: var(--colorPrimary);
}
#pWarrantyDetailsMain .bWarrantyDetails .bHead .bGroup {
  row-gap: 8px;
}
#pWarrantyDetailsMain .bWarrantyDetails .bHead .bGroup .bRow h1,
#pWarrantyDetailsMain .bWarrantyDetails .bHead .bGroup .bRow p {
  font-size: 1rem;
  line-height: normal;
  color: var(--colorPrimary);
}
#pWarrantyDetailsMain .bWarrantyDetails .bHead .bGroup .bRow:last-child p {
  color: var(--colorGray700);
}
#pWarrantyDetailsMain .bWarrantyDetails .bHead .bGroup h1 {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  column-gap: 4px;
}
#pWarrantyDetailsMain .bWarrantyDetails .bHead .bGroup h1:before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background: url(../img/ci/ico-check.svg) center center no-repeat;
  background-size: auto 100%;
}