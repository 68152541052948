:root {
  --colorPrimaryL: #c81631;
  --colorPrimary: #981125;
  --colorPrimaryD: #4e0813;
  --colorSecondL: #FFC692;
  --colorSecond: #7E4C09;
  --colorSecondD: #3f2605;
  --colorGray50: #f5f5f5;
  --colorGray100: #dfe0e0;
  --colorGray200: #d0d1d1;
  --colorGray300: #bbbbbc;
  --colorGray400: #adaeaf;
  --colorGray500: #999a9b;
  --colorGray600: #8b8c8d;
  --colorGray700: #6d6d6e;
  --colorGray800: #545555;
  --colorGray900: #404141;
  --colorBlack: #0B0C0C;
  --colorWhite: #ffffff;
  --gradient-primaryL: linear-gradient(180deg, #ED3B52 -8.85%, #AC1B20 100%);
  --gradient-primary: linear-gradient(0deg, #88100D 14.83%, #980823 49.93%);
  --gradient-secoundL: linear-gradient(0deg, #FFC692 -73.08%, #FFEFB6 46.23%, #FFEC8B 103.68%);
  --gradient-secound: linear-gradient(0deg, #D09131 0%, #F8D76D 40.97%, #FFF 79.41%, #F8D76D 105.43%, #FFF 140%);
  --gradient-secoundD: linear-gradient(0deg, #F8D76D 1.23%, #D09131 73.15%, #F8D76D 87.53%, #FFF 109.45%, #FFF 111.64%);
  --gradient-black: linear-gradient(122deg, #181010 25%, #51515d 100%);
  --gradient-grey: linear-gradient(40deg, #adabab 25%, #777 100%);
  --gradient-silver: radial-gradient(91.77% 115.83% at 22.77% 18.75%,#fff 0%,#d9d9d9 100%);
  --baseFontsize: 16px;
}

p {
  font-size: 0.875rem;
  line-height: normal;
}

.jBox-wrapper {
  z-index: 99999 !important;
}

.jBox-overlay {
  z-index: 99998 !important;
  background-color: rgba(0, 0, 0, 0.5);
}

.jBox-Modal .jBox-container {
  overflow: hidden;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  background-color: transparent;
}

.jBox-Modal .jBox-container,
.jBox-Modal.jBox-closeButton-box:before {
  box-shadow: none;
}

.jBox-Modal .jBox-content {
  padding: 0 !important;
  margin: 0 auto !important;
}

.jBox-closeButton-box .jBox-closeButton {
  width: 30px;
  height: 30px;
  background: url(../img/ui/form/ico-close.svg) center center no-repeat;
  background-size: 70% auto;
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  right: 0px;
  top: 0px;
  overflow: hidden;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
}
.jBox-closeButton-box .jBox-closeButton:hover {
  background-color: var(--colorSecond);
}
.jBox-closeButton-box .jBox-closeButton svg {
  display: none;
}

.bModal {
  display: none;
  overflow: hidden;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  padding: 0px;
  background-color: transparent;
  margin: 0px;
  -webkit-box-shadow: 0px 0px 10px rgba(16, 50, 90, 0.2);
  -moz-box-shadow: 0px 0px 10px rgba(16, 50, 90, 0.2);
  box-shadow: 0px 0px 10px rgba(16, 50, 90, 0.2);
}
.bModal.bCard {
  padding: 0;
  background-color: #ffffff;
  padding: 24px;
  overflow: hidden;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.bModal.bCard .bInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 24px;
}
.bModal.bCard .bTitle {
  color: var(--colorPrimary);
  text-align: center;
}
.bModal.bCard .bTitle span {
  font-size: 1.25rem;
  line-height: normal;
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
}
.bModal.bCard .bDetails {
  text-align: center;
  color: var(--colorGray900);
  line-height: 160%;
}
.bModal.bCard .bText {
  margin-bottom: 24px;
  text-align: center;
}
.bModal.bCard .bText p {
  font-size: 1.25rem;
  line-height: normal;
}
@media (min-width: 768px) {
  .bModal.bCard .bText p {
    font-size: 1.5rem;
    line-height: normal;
  }
}

.bModal.VDO .bYoutube {
  width: 70vw;
  padding-top: 56.25%;
}
@media (min-width: 767px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .bModal.VDO .bYoutube {
    width: 80vw;
  }
}
@media (max-width: 767px) {
  .bModal.VDO .bYoutube {
    width: 90vw;
    padding: 0 20px;
    padding-top: 56.25%;
  }
}
.bModal.VDO iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.bModal.Image .bPic {
  width: 90vw;
}
@media (min-width: 768px) {
  .bModal.Image .bPic {
    width: 80vw;
  }
}
.bModal.Image a {
  color: #ffffff;
  margin-top: 16px;
}
.bModal.Image img {
  height: auto;
  width: 100%;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .bModal.Image img {
    width: auto;
    max-height: 80vh;
  }
}

a.btnCloseModalIcon {
  overflow: hidden;
  width: 24px;
  height: 24px;
  background: url(../img/ui/form/ico-close.svg) center center no-repeat;
  position: absolute;
  top: 8px;
  right: 8px;
}

#ModalFilter.bCard {
  padding: 24px 16px;
}
#ModalFilter.bCard .bInner {
  row-gap: 0;
}
#ModalFilter .bFilterForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
#ModalFilter .bBtn {
  display: flex;
  column-gap: 16px;
}

#ModalConfirm .bCard .bInner {
  row-gap: 16px;
}
#ModalConfirm .bBtn {
  display: flex;
  column-gap: 16px;
}

.bOtp {
  width: 100%;
  padding: 0;
}
.bOtp > p {
  text-align: center;
  color: var(--colorGray700);
  margin-bottom: 18px;
  font-size: 1rem;
  line-height: normal;
}
.bOtp .bFrom .control-group.otp {
  display: flex;
  column-gap: 10px;
  justify-content: center;
  margin-bottom: 24px;
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
}
.bOtp .bFrom .control-group.otp input[type=text] {
  max-width: 48px;
  height: 48px;
  text-align: center;
  font-size: 2rem;
  line-height: normal;
  padding: 0;
}
.bOtp .bFrom .control-group.otp input::-webkit-outer-spin-button,
.bOtp .bFrom .control-group.otp input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.bOtp .bFrom .control-group.otp input[type=number] {
  -moz-appearance: textfield;
}
.bOtp .bFrom .bTextSend {
  margin-bottom: 12px;
  text-align: center;
  color: var(--colorGray700);
}
.bOtp .bFrom .bTextSend p {
  font-size: 1rem;
  line-height: normal;
}
.bOtp .bFrom .bTextSend a {
  color: var(--colorGray900);
  text-decoration: underline;
}
.bOtp .bFrom .tError {
  display: none;
  color: #f85b56;
  font-weight: 300;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
}
.bOtp .bFrom .tError:before {
  content: "";
  display: block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background: url(../img/ui/form/ico-error.svg) center center no-repeat;
  background-size: contain;
}
.bOtp .bFrom.error .control-group input {
  border-color: #f85b56;
}
.bOtp .bFrom.error .tError {
  display: flex;
}
.bOtp .bBtn {
  display: flex;
  align-content: center;
  flex-direction: column;
}
.bOtp .bBtn span {
  font-size: 0.875rem;
  line-height: normal;
  color: #424242;
  text-align: center;
  margin: 12px 0;
}
.bOtp .bBtn .btn {
  max-width: 260px;
  max-width: max-content;
  margin: 0 auto;
}

#pRegisterMain .bRegister {
  position: relative;
  z-index: 1;
  margin-inline: auto;
  width: calc(100% - 48px);
  background: var(--gradient-secoundD);
  overflow: hidden;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 24px;
  align-items: center;
  position: relative;
  overflow: inherit;
  margin-top: -10px;
  padding: 20px;
  padding-top: 32px;
}
#pRegisterMain .bRegister:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: #ffffff;
  overflow: hidden;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
#pRegisterMain .bRegister > * {
  position: relative;
}
#pRegisterMain .bRegister > h3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
  background: var(--gradient-primaryL);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.25rem;
  line-height: normal;
  line-height: 150%;
  font-weight: normal;
}
#pageRegister .bRegister .bBtnType .control-group {
  width: 100%;
}
#pageRegister .bRegister .bBtnType .control-group:last-child {
  margin-bottom: 0;
}
#pageRegister .bRegister .bBtnType .control-group.imgRadio {
  display: flex;
  max-width: inherit;
  justify-content: center;
  width: auto;
  column-gap: 32px;
}
#pageRegister .bRegister .bBtnType .control-group.imgRadio .control.control--radio {
  width: 100%;
  height: auto;
  padding: 0;
  margin-bottom: 0;
}
#pageRegister .bRegister .bBtnType .control-group.imgRadio .control.control--radio input {
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
}
#pageRegister .bRegister .bBtnType .control-group.imgRadio .control.control--radio input ~ .control__indicator {
  position: relative;
  width: 124px;
  height: 124px;
  background: var(--gradient-secoundD);
  overflow: hidden;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: none;
  text-align: center;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 4px;
}
#pageRegister .bRegister .bBtnType .control-group.imgRadio .control.control--radio input ~ .control__indicator:after {
  display: none;
}
#pageRegister .bRegister .bBtnType .control-group.imgRadio .control.control--radio input ~ .control__indicator .bPic {
  width: 72px;
  height: 72px;
  background: center center no-repeat;
}
#pageRegister .bRegister .bBtnType .control-group.imgRadio .control.control--radio input ~ .control__indicator span {
  font-size: 1rem;
  line-height: normal;
  color: #393939;
}
#pageRegister .bRegister .bBtnType .control-group.imgRadio .control.control--radio input:checked ~ .control__indicator {
  background: var(--gradient-primaryL);
}
#pageRegister .bRegister .bBtnType .control-group.imgRadio .control.control--radio input:checked ~ .control__indicator span {
  background: var(--gradient-secound);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#pageRegister .bRegister .bBtnType .control-group.imgRadio .control.control--radio.verify input ~ .control__indicator .bPic {
  background-image: url(../img/ci/ico-verifiyuser.svg);
}
#pageRegister .bRegister .bBtnType .control-group.imgRadio .control.control--radio.verify input:checked ~ .control__indicator .bPic {
  background-image: url(../img/ci/ico-verifiyuser-active.svg);
}
#pageRegister .bRegister .bBtnType .control-group.imgRadio .control.control--radio.new input ~ .control__indicator .bPic {
  background-image: url(../img/ci/ico-newuser.svg);
}
#pageRegister .bRegister .bBtnType .control-group.imgRadio .control.control--radio.new input:checked ~ .control__indicator .bPic {
  background-image: url(../img/ci/ico-newuser-active.svg);
}
#pageRegister .bRegister > p {
  font-size: 0.75rem;
  line-height: normal;
  text-align: center;
  color: var(--colorGray700);
  line-height: 140%;
}

#pageRegisterVerify .control-group.searchtype {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
}
#pageRegisterVerify .bRow.birthday {
  display: none;
}

#pageRegisterResult .bForm .bRow .bText {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  font-size: 0.875rem;
  line-height: normal;
  color: var(--colorGray700);
}
#pageRegisterResult .bForm .bRow .bText span {
  font-size: 1rem;
  line-height: normal;
  background: var(--gradient-primaryL);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#pageRegisterResult .bForm .bRow .bText p {
  font-size: 0.75rem;
  line-height: normal;
  color: var(--colorGray700);
}

#pageRegisterSuccess .wrapContent {
  padding-bottom: 0;
  position: relative;
}
#pageRegisterSuccess .wrapContent #pWelcomeMain {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 32px;
  height: 100%;
}
#pageRegisterSuccess .wrapContent #pWelcomeMain .bText {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
#pageRegisterSuccess .wrapContent #pWelcomeMain .bText h1 {
  font-family: "sri_sury_wong", sans-serif;
  background: linear-gradient(0deg, #D09131 -19.86%, #F8D76D 25.21%, #FFF 64.93%, #F8D76D 87.53%, #FFF 111.64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3rem;
  line-height: normal;
}
#pageRegisterSuccess .wrapContent #pWelcomeMain .bText img {
  margin-bottom: 16px;
}
#pageRegisterSuccess .wrapContent #pWelcomeMain .bText p {
  color: #ffffff;
  text-align: center;
  font-size: 1rem;
  line-height: normal;
  zoom: 1.25;
}
#pageRegisterSuccess .wrapContent #pWelcomeMain > img {
  zoom: 1.25;
}